footer {
    background: #000;

    a {
        &:hover {
            color: #fff;
        }
    }

}

.footer-bottom {
    color: #7D899E;
    font-size: 14px;
    padding: 38px 0;

    a {
        color: #7D899E;
        font-size: 14px;

    }

    ul {
        display: flex;
        align-items: center;

        li {
            margin-right: 70px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.footer-top {
    display: flex;
    justify-content: space-between;
    padding: 28px 0 50px;

    @media (max-width: 1199px) {
        padding: 28px 0 30px;
    }

    p {
        color: #7D899E;
        font-size: 16px;
        margin-top: 12px;
        line-height: 21px;
    }

    .col-left {
        max-width: 320px;
    }

    .footer-widget {
        margin-right: 100px;

        @media (max-width: 991px) {
            margin-right: 60px;
        }
    }
}



.footer-widget {
    .widget-title {
        font-size: 14px;
        color: #CBCBCB;
        margin-bottom: 20px;
    }
}

.widget-about {
    li {
        a {
            font-size: 14px;
            color: #7D899E;
            transition: ease-in-out 0.5s;


        }

        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 767px) {
    .footer-top {
        flex-direction: column;

        .col-left {
            max-width: 100%;
        }

        .col-right {
            justify-content: space-between;
            margin-top: 20px;
        }
    }

    .footer-bottom ul li {
        margin-right: 25px;
    }
}

@media (max-width: 575px) {
    .footer-bottom {
        &.flex_jus_item {
            flex-direction: column;
        }

        .col-left {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 480px) {
    .footer-top .footer-widget {
        margin-right: 20px;
    }

    .footer-bottom ul li {
        margin-right: 12px;
    }
}