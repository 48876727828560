body {
    margin: 0;
    padding: 0;
    background: #fff;
    scroll-behavior: smooth;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

ul,
ol {
    list-style: none;
    padding: 0;
}

button {
    border: none;
}

button:focus,
input:focus {
    outline: none;
}

.head1 {
    font-size: 60px;
    color: #fff;
}

.head2 {
    color: #000000;
    font-size: 40px;
    font-family: baskervill_B;
    line-height: 46px;

    @media (max-width: 1440px) {
        font-size: 38px;
    }

    @media (max-width: 1320px) {
        font-size: 34px;
    }

    @media (max-width: 991px) {
        font-size: 33px;
        line-height: 44px;
    }

    @media (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
    }

    @media (max-width: 575px) {
        font-size: 26px;
        line-height: 34px;
    }

    @media (max-width: 374px) {
        font-size: 24px;
        line-height: 34px;
    }
}

.head3 {
    line-height: 40px;
    font-size: 34px;
    color: #000000;
    margin-bottom: 10px;
    font-family: baskervill_B;

    @media (max-width: 991px) {
        font-size: 26px;
        line-height: 36px;
    }

    @media (max-width: 767px) {
        font-size: 24px;
        line-height: 34px;
    }

    @media (max-width: 575px) {
        font-size: 20px;
        line-height: 26px;
    }
}

.head4 {
    font-size: 30px;


}

.head5 {
    font-size: 24px;
    font-family: baskervill_B;
    color: #000000;
}

.txt {
    font-size: 18px;
    color: #000000;
    line-height: 30px;
    font-family: Helvetica;

    @media (max-width: 575px) {
        font-size: 16px;
        line-height: 26px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}