@font-face {
    font-family: Helvetica;
    src: url("../fonts/Helvetica/Helvetica.ttf");
    font-weight: 400;
}

@font-face {
    font-family: Helvetica_SB;
    src: url("../fonts/Helvetica/Helvetica-Bold.ttf");
    font-weight: 700;
}

@font-face {
    font-family: baskervill_R;
    src: url("../fonts/baskervill/BASKE1.ttf");
    font-weight: 400;
}

@font-face {
    font-family: baskervill_B;
    src: url("../fonts/baskervill/BaskervilleBoldBT.ttf");
    font-weight: 600;
}