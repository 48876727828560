.modalBoay {
    padding: 40px 30px;

    @media (max-width: 575px) {
        padding: 30px 15px;
    }

    .txt {
        line-height: 24px;
    }

    .head5 {
        font-family: Helvetica_SB;
        font-size: 20px;

        @media (max-width: 575px) {
            font-size: 18px;
        }

        a {
            color: #1274CE;
            text-decoration: underline;
        }
    }

    .btnC {
        min-width: 100px;
    }

    .linkM {
        color: #1274CE;
        text-decoration: underline;
    }
}