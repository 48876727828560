.cusContainer {
    max-width: 1440px;
    margin: auto;
    padding: 0 100px;

    @media (max-width: 1366px) {
        padding: 0 60px;
    }

    @media (max-width: 1199px) {
        padding: 0 30px;
    }

    @media (max-width: 991px) {
        padding: 0 25px;
    }

    @media (max-width: 767px) {
        padding: 0 15px;
    }

    @media (max-width: 575px) {
        padding: 0 10px;
    }
}

.flex_jus_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mt30 {
    margin-top: 30px;

    @media (max-width: 575px) {
        margin-top: 20px;
    }
}

.mb30 {
    margin-bottom: 30px;

    @media (max-width: 575px) {
        margin-bottom: 20px;
    }
}

.whiteBox {
    border: 1px solid #E8E8E8;
    padding: 30px;
    border-radius: 20px;
    background: #fff;

    @media (max-width: 767px) {
        padding: 20px;
    }

    @media (max-width: 575px) {
        padding: 20px 10px;
    }
}

.flex {
    display: flex;
}

.w50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.br20 {
    border-radius: 20px;
}

.redTxt {
    color: #F64722;
    text-transform: uppercase;
    font-size: 24px;
    font-family: baskervill_B;

    @media (max-width: 575px) {
        font-size: 20px;
    }
}

// banner
.main_bg {
    background-color: #e9e9e9;
    background: url("../images/manbanner.gif") center no-repeat;
    background-size: cover;
    padding: 110px 0;

    .mainRS_img {
        max-width: 510px;
        margin-left: auto;
        box-shadow: -20px -16px 54px 0px #00000040;
        border-radius: 40px;
        width: 100%;

    }

    .txt {
        font-size: 20px;
        font-family: baskervill_R;
        line-height: 30px;
    }

    @media (max-width: 991px) {
        padding: 60px 0;
    }

    @media (max-width: 575px) {
        .head5 {
            font-size: 18px;
        }
    }

}

.main_input {

    .inputItems {
        width: 300px;

        .fillIn {
            width: 100%;
            background: transparent;
            border: 1px solid #000;
            border-radius: 40px;
            min-height: 44px;
            font-size: 14px;
            padding: 10px;
            line-height: 0;
            font-family: Helvetica;
        }

        @media (max-width: 991px) {
            width: 300px;
        }
    }

}

main {

    .main_input .inputItems,
    .addNum {
        width: 300px;
    }
}

// ====================
.ffDil {
    padding: 100px 0;

    .ffDil_banner {
        background: #D9EDFF;
        border-radius: 20px;

        .ffDilImgBn {
            width: 100%;
            border-radius: 0px 20px 20px 0px;
        }
    }

    .maxWffdil_mtxt {
        max-width: 688px;
        margin: auto;
    }
}

//======================
.specialOffer {
    background: url("../images/specialOffer_bg.png") no-repeat center;
    background-size: cover;
    padding: 63px 0;

    .SpCOFfImg {
        width: 100%;
        max-width: 570px;
        margin-left: auto;
        display: table;
    }

    @media (max-width: 767px) {
        padding: 50px 0;
    }

    @media (max-width: 575px) {
        padding: 30px 0;
    }

}

//========================
.stepbystep {
    padding: 130px 0;

    .stepbystep_group {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 35px;
    }

    .stepbystep_item {
        box-shadow: 10px 14px 74px 0px #0000001A;
        padding: 20px 10px;
        border-radius: 20px;

        .txt {
            font-size: 16px;
            line-height: 30px;
        }
    }
}

//=================
.myprnl {
    padding: 54px 0;
    background-color: #D9EDFF;

    .MyprnlrightImg {
        max-width: 440px;
        width: 100%;
        margin-left: auto;
        display: table;
    }
}

//====================
.benSpcDoc {
    padding: 90px 0;
    background-color: #F1F1F1;
    background: url("../images/benSpcDoc_bg.png") no-repeat right;
    background-size: cover;
    position: relative;

    ul {
        list-style: disc;
        padding-left: 24px;
    }

    @media (max-width: 1199px) {
        &::after {
            position: absolute;
            content: "";
            left: 0;
            background-color: rgba(241, 241, 241, 0.7);
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .cusContainer {
            position: relative;
            z-index: 1;
        }
    }

}

//======================
.specialOfferTwo {
    background: #FFF4F1 !important;
}

// ===================
.understandYP {
    .ustandYPLimg {
        width: 100%;
    }

    .head3 {
        padding: 40px 0;
        margin-bottom: 0;
        max-width: 586px;
        text-transform: uppercase;

        @media (min-width: 768px) and (max-width: 1440px) {
            padding-left: 10px;
        }

        @media (max-width: 991px) {
            // padding: 30px 0;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @media (max-width: 575px) {
            padding: 20px 0;
        }
    }
}

// ======================
.hppbnf {
    background: #F1F1F1;

    ul {
        list-style: disc;
        padding-left: 24px;
        margin-bottom: 0;
    }

    .col-left {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 100px;
        padding-right: 50px;

        @media (max-width: 1366px) {
            padding-left: 60px;
            padding-right: 30px;
        }

        @media (max-width: 1199px) {
            padding-left: 30px;
            padding-right: 15px;
            padding-top: 30px;
            padding-bottom: 30px;
        }

        @media (max-width: 991px) {
            padding-left: 25px;
        }

        @media (max-width: 767px) {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
        }
    }

    .col-right {
        width: 50%;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .hppbnf_SB {
        flex-direction: row-reverse;

        .col-left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-right: 100px;
            padding-left: 50px;

            @media (max-width: 1366px) {
                padding-right: 60px;
                padding-left: 30px;
            }

            @media (max-width: 1199px) {
                padding-right: 30px;
                padding-left: 15px;
                padding-top: 30px;
                padding-bottom: 30px;
            }

            @media (max-width: 991px) {
                padding-right: 25px;
            }

            @media (max-width: 767px) {
                padding-left: 0;
                padding-right: 0;
                width: 100%;
            }
        }
    }
}

// ===========================
.ovSs {
    background: url("../images/ovSs_bg.png") center no-repeat;
    background-size: cover;
    padding: 60px 0;

    .head2 {
        max-width: 600px;
        margin: auto;
        text-align: center;
        color: #fff;
    }

    .txt {
        color: #fff;
        max-width: 950px;
        margin: auto;
        text-align: center;
    }
}

// ==========================
.jjf_in {
    background: url("../images/jjf_inbg.png") center no-repeat;
    background-size: 100% 100%;
    padding: 100px 0 50px;
    min-height: 80vh;

    @media (max-width: 991px) {
        padding: 60px 0 50px;
    }

    @media (max-width: 575px) {
        padding: 30px 0 30px;
    }

    .jjf_inMax {
        max-width: 688px;
    }

    .head2 {
        line-height: 60px;

        @media (max-width: 991px) {
            line-height: 50px;
        }

        @media (max-width: 575px) {
            line-height: 40px;
        }
    }
}

@media (max-width: 1199px) {
    .stepbystep {
        padding: 100px 0;
    }

    .stepbystep .stepbystep_group {
        gap: 20px;
    }

    .ffDil .ffDil_banner {
        padding: 10px;

        .ffDilImgBn {
            border-radius: 10px;
        }
    }
}

@media (max-width: 991px) {
    .stepbystep .stepbystep_group {
        grid-template-columns: repeat(2, 1fr);
    }

    .stepbystep {
        padding: 70px 0;
    }

    .ffDil {
        padding: 50px 0;
    }

    .benSpcDoc {
        padding: 60px 0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .ffDil .whiteBox {
        .head2 {
            font-size: 32px;
        }
    }

    .specialOffer {
        .head2 {
            font-size: 32px;
        }
    }
}

@media (max-width: 767px) {
    .myprnl .MyprnlrightImg {
        max-width: 400px;
        margin: 30px auto 0;
        display: table;
    }

    .stepbystep {
        padding: 50px 0;
    }

    .benSpcDoc {
        padding: 50px 0;
    }

    .main_bg {
        padding: 50px 0;

        .mainRS_img {
            max-width: 400px;
            margin: 30px auto 0;
            display: table;
        }
    }

    .specialOffer .SpCOFfImg {
        max-width: 400px;
        margin: 30px auto 0;
        display: table;
    }
}

@media (max-width: 575px) {
    .myprnl {
        padding: 30px 0;
    }

    .stepbystep {
        padding: 30px 0;
    }

    .ovSs {
        padding: 30px 0;
    }

    .benSpcDoc {
        padding: 30px 0;
    }

    .ffDil {
        padding: 30px 0;
    }

    .main_bg {
        padding: 30px 0;

        .txt {
            font-size: 20px;
            line-height: 28px;
        }
    }

    .stepbystep .stepbystep_group {
        grid-template-columns: repeat(1, 1fr);
        gap: initial;

        .stepbystep_item {
            margin-bottom: 20px;
        }
    }
}