header {
    background-color: #FAFAFA;
    padding: 25px 0;

    @media (max-width: 575px) {
        padding: 20px 0;
    }

    .sildNAv {
        display: flex;
        align-items: center;
    }

    .navLink_h {
        ul {
            display: flex;
            align-items: center;
        }

        li {
            margin-right: 30px;

            a {
                color: #000000;
                font-size: 16px;
                font-family: Helvetica_SB;

                &.active {
                    color: #F64722;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 575px) {
    header {
        .flex_jus_item {
            flex-direction: column;
        }

        .navLink_h {
            margin-top: 10px;
        }
    }
}