.container {
    max-width: 1200px;
    margin: auto;
    padding: 50px 15px 40px;

    ul {
        padding-left: 15px;
        list-style-type: disc;
    }

    li {
        margin-bottom: 7px;
        line-height: 1.5;
        font-size: 16px;
    }

    address {
        line-height: 1.6;
    }

    p {
        font-size: 16px;
        line-height: 1.5;
        margin-top: 0;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 7px;
    }

    a {
        color: #007bff !important;
        margin: 0 3px;
        text-decoration: underline;
    }
}