.contactus {
    padding: 50px 0;

    // background: #fafafa;
    .contactStore {
        object-fit: cover;
        border-radius: 0px 10px 10px 0px;
        height: 100%;
    }

    .inputGroup {
        .addNum {
            width: 100%;
            background-color: #f4f4f9;
            border: 1px solid #d7defa;
            border-radius: 10px;
            min-height: 50px;
        }

        .addNum .afLin::after {
            display: none;
        }
    }

    .contact_form {
        background: #fff;
        padding: 40px 30px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        position: relative;
        z-index: 2;

        @media (max-width: 575px) {
            padding: 20px 10px;
        }
    }
}

.afRoundImg {
    position: relative;
    background-color: #fafafa;

    &::after {
        position: absolute;
        content: "";
        width: 450px;
        height: 88px;
        background: url("../images/rounderImgbottom.png");
        right: 0;
        top: 0;
        z-index: 1;
    }
}

@media (max-width: 1199px) {
    .contactus {
        padding: 30px 0;
    }
}

@media (max-width: 767px) {
    .contactStore {
        display: none;
    }

    .contactus {
        padding-top: 0;

        .inputControl {
            height: 45px;
        }

        .inputGroup .addNum {
            min-height: 45px;
        }
    }


}

@media (max-width: 575px) {
    .contactus {
        .textarea {
            min-height: 85px;
        }
    }

}