.mainBoxDr {
    color: #FE8929;
    font-size: 14px;
    border-radius: 20px;
    background: #1D1D1D;
    display: inline-block;
    padding: 8px 20px;
    margin-bottom: 24px;
}

.innerMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding: 70px 0;
    background: #000;
    min-height: 255px;
    position: relative;
    display: none;

    &::after {
        position: absolute;
        content: "";
        width: 450px;
        height: 88px;
        background: url("../images/rounderImgTop.png");
        right: 0;
        bottom: 0;
    }

    @media (max-width: 767px) {
        padding: 70px 0;
    }
}

.headspan {
    font-size: 24px !important;
}

.profile_info {
    color: #3D3D3D;
    font-family: baskervill_R;
    font-size: 24px;

    @media (max-width: 575px) {
        font-size: 18px;
    }
}

.aboutInfo {
    background: #D9EDFF;
    padding: 75px 30px;
    padding-right: 360px;
    border-radius: 20px;
    position: relative;

    @media (max-width: 991px) {
        padding: 30px;
        padding-top: 270px;
    }

    @media (max-width: 767px) {
        padding: 15px;
        padding-top: 270px;
    }

    @media (max-width: 575px) {
        padding: 10px;
        padding-top: 270px;
    }

    &.bimcolor {
        background: #C6E6D3;

    }

    .profileV {
        position: absolute;
        bottom: 0;
        right: 0;

        img {
            width: 100%;
            max-width: 340px;
        }

        @media (max-width: 991px) {
            position: absolute;
            top: 0;
            left: 34px;
            right: auto;
            text-align: center;

            img {
                max-width: 250px;
                height: 250px;
            }
        }

        @media (max-width: 575px) {
            left: auto;
        }
    }
}

.aboutSF {
    padding: 30px 0 100px;

    @media (max-width: 991px) {
        padding: 30px 0 50px;
    }

    .whiteBox {
        position: relative;
        z-index: 2;
    }

    ul {
        list-style: disc;
        padding-left: 24px;

        .txt {
            line-height: 1.5;
        }
    }

    @media (min-width: 992px) and (max-width: 1320px) {
        .head2 {
            font-size: 36px;

        }
    }

    .head2 {
        @media (max-width: 1199px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: 575px) {
            font-size: 22px;

            .headspan {
                font-size: 18px !important;
            }
        }
    }

}

.whtboxBn {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    @media (max-width: 575px) {
        padding: 20px 10px;
    }
}

.grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
        gap: initial;

        .whtboxBn {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.aboutInfo .profileV {
    &.profileV-no {
        background: #FFFFFF;
        width: 300px;
        height: 330px;
        bottom: 20px;
        right: 20px;
    }
}