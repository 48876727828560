.faq {
    padding: 50px 0 100px;

    @media (max-width: 991px) {
        padding: 50px 0 50px;
    }

    @media (max-width: 575px) {
        padding: 30px 0 50px;
    }

    .lightBlue-img {
        margin-bottom: 10px;

        img {
            width: 100%;
        }
    }

    .accordion-button::after {
        transition: ease-in-out 0s;
    }

    .accordion-item {
        margin-bottom: 20px;
        border: none;
        background: #F3F5F7;
        border-radius: 10px;

        .collapsed {
            background: #F3F5F7;
            border-radius: 10px;
            box-shadow: none !important;
            transition: ease-in-out 0.5s;
            font-size: 20px;
            font-family: baskervill_B;

            @media (max-width: 575px) {
                font-size: 16px;
            }
        }

        .accordion-button::after {
            background-image: url("../images/accorhide.svg");
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url("../images/accorShow.svg");
        }


        .accordion-body {
            background: #F3F5F7;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding-top: 0;
            font-size: 18px;
            color: #000000;
            font-family: Helvetica;

            @media (max-width: 575px) {
                font-size: 14px;
            }
        }
    }

    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
        color: #000000;
        font-family: baskervill_B;
    }

    .accordion-button:not(.collapsed) {
        background: #F3F5F7;
        box-shadow: none;
        border-radius: 10px;
        color: #000000;
        font-size: 20px;
        font-family: baskervill_B;

        @media (max-width: 575px) {
            font-size: 16px;
        }
    }

}

.lightBlue {
    background: #F3F5F7;
    padding: 20px;
    border-radius: 10px;
    // min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: end;

    .btnC {
        padding: 12px 30px;
    }

}

.smallhead {
    color: #000000;
    font-size: 16px;
    font-family: baskervill_B;
}