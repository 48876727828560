.btnC {
    background: #F74F2A;
    border: 1px solid;
    border-image-source: linear-gradient(268.2deg, #FE892A -9.26%, #F74B22 78.46%);
    color: #fff;
    padding: 12px 30px;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    font-family: Helvetica_SB;

    @media (max-width: 575px) {
        padding: 12px 40px;
    }
}

.addNum {
    display: flex;
    border-radius: 20px;
    border: 1px solid #000000;
    background: #EBEBEB;
    min-height: 44px;
    padding: 0 10px;
    width: 200px;
    transition: ease-in-out 0.5s;
    font-family: Helvetica;

    @media (max-width: 991px) {
        width: 300px;
    }

    .addinput {
        width: 100%;
        background: transparent;
        border: none;
        font-size: 14px;
        padding: 0 5px;
        font-family: Helvetica;
        line-height: 0;
    }

    .afLin {
        display: flex;
        position: relative;
        padding-right: 8px;

        &::after {
            position: absolute;
            content: "";
            background: #000000;
            height: 40%;
            top: 50%;
            width: 1px;
            right: 5px;
            transform: translateY(-50%);
        }
    }

    .addnumSelect {
        border: 0;
        background: transparent;
        outline: none !important;
        font-size: 14px;
    }
}

.inputControl {
    font-size: 16px;
    height: 50px;
    padding: 5px 15px;
    background-color: #f4f4f9;
    border: 1px solid #d7defa;
    border-radius: 10px;
    width: 100%;
    margin-top: 8px;
    transition: ease-in-out 0.3s;
    outline: none !important;

    &:focus {
        border: 1px solid #000000;
    }

    cursor: pointer;
}

.textarea {
    font-size: 16px;
    min-height: 120px;
    padding: 5px 15px;
    background-color: #f4f4f9;
    border: 1px solid #d7defa;
    border-radius: 10px;
    width: 100%;
    margin-top: 8px;
    transition: ease-in-out 0.3s;
    outline: none !important;
    resize: none;
    cursor: pointer;

    &:focus {
        border: 1px solid #000000;
    }
}

.HtermCond {
    label {
        font-family: baskervill_R;
        font-size: 12px;
    }
}

label {
    font-size: 14px;
    color: #000000;
    font-family: baskervill_B;

    sup {
        color: #FF3636;
        font-size: 16px;
        top: 0;
    }
}

.error {
    color: red;
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-top: 3px;
}

.requiredStar {
    background-image: url("../images/starR.svg") !important;
    background-position: center right 13px !important;
    background-repeat: no-repeat !important;
    background-size: 15px 15px !important;
    padding-right: 35px !important;
}